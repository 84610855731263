// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

import "../css/tailwindcss.css"
import "stylesheets/application"

import "core-js";
import "regenerator-runtime/runtime";

import Vue from 'vue'
//import TurbolinksAdapter from 'vue-turbolinks'
//Vue.use(TurbolinksAdapter)


window.Vue = require('vue');

import smoothScroll from 'vue-smoothscroll'
Vue.use(smoothScroll)

import VModal from 'vue-js-modal'
Vue.use(VModal)

